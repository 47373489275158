var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page import-list"},[_c('div',{staticClass:"page-header bg-white"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center w-100"},[_c('h3',{staticClass:"page-title my-auto"},[_vm._v("Imports")]),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.stopPropagation();return _vm.openUpsertModal($event)}}},[_vm._v(" Carica nuova dati ")])])]),_c('div',{staticClass:"page-content"},[_c('data-table',{attrs:{"columns":_vm.columns,"values":_vm.imports,"loading":_vm.loading},on:{"selected":_vm.onSelected},scopedSlots:_vm._u([{key:"column_file",fn:function(ref){
var value = ref.value;
return [_c('a',{attrs:{"href":value.url,"target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(value.filename)+" ")])]}},{key:"column_status",fn:function(ref){
var value = ref.value;
return [_c('span',{class:_vm.statusClass(value.status)},[_vm._v(" "+_vm._s(_vm.translatedStatus(value.status))+" ")])]}},{key:"column_errors",fn:function(ref){
var value = ref.value;
return [(value.validationErrors)?_c('button',{staticClass:"btn btn-link",on:{"click":function($event){$event.stopPropagation();return _vm.openErrorsModal(value)}}},[_vm._v(" Visualizza errori ")]):_vm._e()]}},{key:"column_importedResources",fn:function(ref){
var value = ref.value;
return [(value.importedResources && value.importedResources.length)?_c('button',{staticClass:"btn btn-link",on:{"click":function($event){$event.stopPropagation();return _vm.openImportedResourcesModal(value)}}},[_vm._v(" Visualizza Risorse Importate ")]):_vm._e()]}},{key:"column_actions",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"d-flex align-items-center",class:value.isToApprove ? 'justify-content-between' : 'justify-content-end'},[(value.isToApprove)?_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.stopPropagation();return _vm.openImportModal(value)}}},[_vm._v(" Importa ")]):_vm._e(),_c('fa-icon',{staticClass:"clickable ml-3 text-primary",attrs:{"icon":"trash"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openConfirmModal(value)}}})],1)]}},{key:"no-results-label",fn:function(){return [(_vm.imports && _vm.imports.length == 0 && !_vm.loading)?_c('empty-view',{attrs:{"primaryText":"Non c'è ancora nessun Import","secondaryText":"Creane uno adesso!"}}):_vm._e()]},proxy:true},{key:"loading",fn:function(){return [_c('div',{staticClass:"d-flex w-100"},[_c('fa-icon',{staticClass:"text-primary m-auto",attrs:{"icon":"circle-notch","size":"2x","spin":""}})],1)]},proxy:true}])}),(_vm.meta)?_c('div',{staticClass:"d-flex align-items-center w-100"},[_c('v-paginator',{staticClass:"mt-3",attrs:{"meta":_vm.meta,"loading":_vm.loading,"directionalArrows":false},on:{"change":function($event){return _vm.loadItems($event)}}}),_c('div',{staticClass:"ml-3"},[(_vm.loading)?_c('fa-icon',{staticClass:"m-auto text-primary",attrs:{"icon":"spinner","size":"2x","spin":""}}):_vm._e()],1)],1):_vm._e()],1),_c('import-upsert-modal',{ref:"upsertModal",on:{"created":_vm.loadItems}}),_c('confirm-modal',{ref:"confirmModal"}),_c('import-modal',{ref:"importModal",on:{"hide":_vm.loadItems}}),_c('import-errors-modal',{ref:"importErrorsModal"}),_c('import-imported-resources-modal',{ref:"importedResourcesModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }