




























































































































import { Component, Mixins, Ref } from 'vue-property-decorator';

import ImportsMixin, { ImportsMixinTags } from '@/mixins/http/ImportsMixin';
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import Import, { STATUS_CLASSES, STATUS_NAMES } from '@/models/Import';
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import { easync } from '@/utils/http';
import { showErrorToast } from '@/utils/Toast';
import VPaginator from '@/components/VPaginator.vue';
import ImportUpsertModal from '@/components/Import/UpsertModal.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import ImportModal from '@/components/Import/ImportModal.vue';
import ImportErrorsModal from '@/components/Import/ErrorsModal.vue';
import ImportImportedResourcesModal from '@/components/Import/ImportedResourcesModal.vue'; 

@Component({
  components: {
    DataTable,
    VPaginator,
    ImportUpsertModal,
    ConfirmModal,
    ImportModal,
    ImportErrorsModal,
    ImportImportedResourcesModal
  }
})
export default class ImportList extends Mixins(ImportsMixin) {
  private imports: Import[] = [];

  @RegisterHttp(ImportsMixinTags.Index) readonly indexRequest!: RequestData;

  @Ref() readonly upsertModal!: ImportUpsertModal;
  @Ref() readonly confirmModal!: ConfirmModal;
  @Ref() readonly importModal!: ImportModal;
  @Ref() readonly importErrorsModal!: ImportErrorsModal;
  @Ref() readonly importedResourcesModal!: ImportImportedResourcesModal;

  get loading() {
    return this.indexRequest?.loading;
  }

  get meta() {
    return this.indexRequest?.meta;
  }

  get columns() {
    return [
      { name: 'name', label: 'Nome' },
      { name: 'importerType', label: 'Tipo di import' },
      { name: 'file', label: 'File' },
      { name: 'status', label: 'Stato' },
      { name: 'errors', label: 'Errori' },
      { name: 'importedResources', label: 'Risorse Importate' },
      { name: 'actions', label: '' }
    ]
  }

  statusClass(status: string) {
    return STATUS_CLASSES[status];
  }

  translatedStatus(status: string) {
    return STATUS_NAMES[status];
  }

  mounted() {
    this.loadItems();
  }

  private async loadItems(page = 1) {
    const payload = {
      page: page
    }
    const [data, errors] = await easync(this.getImports(payload, ImportsMixinTags.Index));

    if (errors) {
      this.imports = [];
      showErrorToast('Si è verificato un errore nel caricare gli Import');
    }

    this.imports = data;
  }

  private openUpsertModal() {
    this.upsertModal.show();
  }

  private onSelected(data: Import) {
    this.upsertModal.show(data);
  }

  private async openConfirmModal(value: Import) {
    const message = "Sei sicuro di voler eliminare questo Import?";
    const confirmed = await (this.confirmModal as any).confirm(message);
    if (confirmed) {
      this.onDelete(value.id);
    }
  }

  private async onDelete(id: string) {
    const [data, errors] = await easync(this.destroyImport(id, ImportsMixinTags.Destroy));

    if (errors) {
      showErrorToast('Impossibile eliminare l\'Import');
    } else {
      this.loadItems();
    }
  }

  private openImportModal(value: Import) {
    this.importModal.show(value);
  }

  private openErrorsModal(value: Import) {
    this.importErrorsModal.show(value.validationErrors);
  }

  private openImportedResourcesModal(value: Import) {
    this.importedResourcesModal.show(value.importedResources || []);
  }
}
