




























import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import OrderSelect from '@/components/Order/Select.vue';
import OrderLineMultiSelect from '@/components/OrderLine/MultiSelect.vue';
import { Order, OrderLine } from '@/models';

@Component({
  components: {
    OrderSelect,
    OrderLineMultiSelect
  }
})
export default class ZakekeImporterParams extends Vue {
  @Prop({ required: true }) readonly value!: any;

  private params: any = {};
  private selectedOrder: Order | null = null;

  created() {
    this.params = { ...this.value };
  }

  @Watch('params', { deep: true })
  onParamsChanged() {
    this.$emit('input', this.params);
  }

  private onOrderSelected(order: Order) {
    this.selectedOrder = order;
    this.params.orderExternalId = order.externalId;
  }

  private onOrderLineSelected(value: any) {
    this.params.orderLineIds = value.ids;
  }
}
