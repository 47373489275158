


































































import { ImportsMixinTags } from '@/mixins/http/ImportsMixin';
import { ImportPayload } from '@/models/Import';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import withErrors from '@/directives/WithErrors';
import FileUploader from '@/components/FileUploader.vue';
import ImporterTypeSelect from '@/components/Import/ImporterTypeSelect.vue';
import { ImporterType } from '@/models';

@Component({
  components: {
    FileUploader,
    ImporterTypeSelect
  },
  directives: {
    withErrors
  }
})
export default class ImportUpsertModal extends Vue {
  @Prop({ required: true }) readonly value!: ImportPayload | null;

  private importPayload: ImportPayload | null = null;
  private file: any = null;
  private selectedImporterType: ImporterType | null = null;

  get updateMode() {
    return this.importPayload.id !== null;
  }

  get upsertTag() {
    return this.updateMode ? ImportsMixinTags.Update : ImportsMixinTags.Create;
  }

  get fileRequired() {
    return this.selectedImporterType?.fileRequired;
  }

  get fileExtension() {
    return this.selectedImporterType.fileFormat;
  }
  
  created() {
    this.value ? 
      this.importPayload = {...this.value} :
      this.importPayload = new ImportPayload();
  }

  @Watch('importPayload', { deep: true })
  onImportPaylodChanged() {
    this.$emit('input', this.importPayload);
  }

  @Watch('file', { deep: true })
  onFileChanged() {
    this.importPayload.file = this.file;
    this.$emit('input', this.importPayload);
  }

  @Watch('selectedImporterType', { deep: true })
  onImporterTypeChanged() {
    if (!this.selectedImporterType.fileRequired) {
      this.file = null;
    }
    this.importPayload.importerType = this.selectedImporterType?.type;
  }
}
