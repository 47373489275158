import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_IMPORTS_URL = `${BASE_API_URL}imports`;

export enum ImportsMixinTags {
  Index = 'ImportsIndes',
  Show = 'ImportShow',
  Update = 'UpdateImport',
  Create = 'CreateImport',
  Destroy = 'DestroyImport',
  ImporterTypes = 'ImporterTypes',
  StartImport = 'StartImport'
}

@Component
export default class ImportsMixin extends Vue {

  getImports(data: any, tag: string) {
    return this.$httpGetQuery(BASE_IMPORTS_URL, tag, data);
  }

  getImport(id: number, tag: string) {
    return this.$httpGet(BASE_IMPORTS_URL+"/"+id, tag);
  }

  createImport(data: any, tag: string) {
    return this.$httpPost(BASE_IMPORTS_URL, tag, data);
  }

  updateImport(id: string, data: any, tag: string) {
    return this.$httpPut(BASE_IMPORTS_URL+"/"+id, tag, data);
  }

  destroyImport(id: string|number, tag: string) {
    return this.$httpDelete(BASE_IMPORTS_URL+"/"+id, tag);
  }

  getImporterTypes(tag: string) {
    return this.$httpGet(`${BASE_IMPORTS_URL}/importer_types`, tag);
  }

  startImport(id: string | number, data: any, tag: string) {
    return this.$httpPost(`${BASE_IMPORTS_URL}/${id}/start_import`, tag, data);
  }
}
