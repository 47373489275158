

































import { Vue, Component, Ref, Mixins } from 'vue-property-decorator';

import VModal from '@/components/VModal.vue';

@Component({
  components: { VModal }
})
export default class ImportErrorsModal extends Vue {
  @Ref() readonly modal!: VModal;

  private errors: any[] = [];

  show(errors: any[]) {
    this.errors = [...errors];
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  private errorKeys(error: any) {
    return Object.keys(error);
  }

  private errorKeyValue(error: any, key: string) {
    return error[key];
  }
}
