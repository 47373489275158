










































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import FileUploader from "@/components/FileUploader.vue";

@Component({
  components: {
    FileUploader
  }
})
export default class ProductConfigurationImporterParams extends Vue {
  @Prop({ required: true }) readonly value!: any;

  private params: any = { files: [] };

  created() {
    this.params = {...this.value};
  }

  get files() {
    return this.params.files ?? [];
  }

  get filenames() {
    return this.files.map((file: any) => file.name);
  }

  @Watch('params', { deep: true })
  onParamsChanged() {
    this.$emit('input', this.params);
  }
}
