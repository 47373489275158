





































import { Vue, Component, Ref, Mixins } from 'vue-property-decorator';

import VModal from '@/components/VModal.vue';
import ImportsMixin, { ImportsMixinTags } from '@/mixins/http/ImportsMixin';
import { Import } from '@/models';
import { easync } from '@/utils/http';
import { showErrorToast } from '@/utils/Toast';

import ZakekeImporterParams from '@/components/Import/ZakekeImporterParams.vue';
import ProductConfigurationImporterParams from '@/components/Import/ProductConfigurationImporterParams.vue';
import B2bOrdersImporterParams from '@/components/Import/B2bOrdersImporterParams.vue';

@Component({
  components: {
    VModal,
    ZakekeImporterParams,
    ProductConfigurationImporterParams,
    B2bOrdersImporterParams
  }
})
export default class ImportImportModal extends Mixins(ImportsMixin) {
  @Ref() readonly modal!: VModal;

  private value: Import | null = null;
  private params: any = {};

  show(value: Import) {
    this.value = value;
    this.modal.show();
  }

  hide() {
    this.modal.hide();
    this.params = {};
    this.$emit('hide');
  }

  private async onImport() {
    let payload = {};

    if (this.params.files) {
      const formData = new FormData();
      (this.params.files as any[]).forEach((file: any) => {
        formData.append('importerParams[files[]]', file);
      })
      payload = formData;
    } else {
      payload = { importerParams: {...this.params} };
    }

    const [data, errors] = await easync(this.startImport(this.value.id, payload, ImportsMixinTags.StartImport));
    if (errors) {
      showErrorToast('Si è verificato un errore con l\'Import');
      this.hide();
    } else {
      this.hide();
    }
  }
}
