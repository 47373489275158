



















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import ProductMultiSelect from "@/components/Product/MultiSelect.vue";
import { sourceStore } from '@/store/typed';

@Component({
  components: {
    ProductMultiSelect
  }
})
export default class B2bOrdersImporterParams extends Vue {
  @Prop({ required: true }) readonly value!: any;

  private params: any = {};

  get source() {
    return sourceStore.currentSource;
  }

  created() {
    this.params = { ...this.value };
  }

  @Watch('params', { deep: true })
  onParamsChanged() {
    this.$emit('input', this.params);
  }

  private onProductsSelected(payload: { productIds: string[] }) {
    this.params.blackListedProducts = payload.productIds.map(prod => {
      return prod.split('/')[2]
    });
  }
}
