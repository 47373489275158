























import { Vue, Component, Ref, Mixins } from 'vue-property-decorator';

import VModal from '@/components/VModal.vue';
import ImportUpsertForm from '@/components/Import/UpsertForm.vue';
import Import, { ImportPayload } from '@/models/Import';
import { easync } from '@/utils/http';
import ImportsMixin, { ImportsMixinTags } from '@/mixins/http/ImportsMixin';
import { showErrorToast, showToast } from '@/utils/Toast';
import { sourceStore } from '@/store/typed';

@Component({
  components: {
    VModal,
    ImportUpsertForm
  }
})
export default class ImportUpsertModal extends Mixins(ImportsMixin) {
  @Ref() readonly modal!: VModal;

  private importPayload: ImportPayload | null = null;

  show(data: Import | null = null) {
    if (data) {
      this.feedPayload(data);
    }
    this.modal.show();
  }

  hide() {
    this.resetPayload();
    this.modal.hide();
  }

  private feedPayload(data: Import) {
    this.importPayload = ImportPayload.fromData(data);
  }

  private resetPayload() {
    this.importPayload = new ImportPayload();
  }

  get updateMode() {
    return this.importPayload?.id !== null;
  }

  private onSave() {
    this.updateMode ? this.update() : this.create();
  }

  private async create() {
    this.importPayload.sourceId = sourceStore.currentSource.id;
    const formData = ImportPayload.toFormData(this.importPayload);
    const [data, errors] = await easync(this.createImport(formData, ImportsMixinTags.Create));

    if (data) {
      showToast('Import creato correttamente!');
      this.$emit('created', data);
      this.hide();
    } else {
      showErrorToast('Si è verificato un errore nel creare l\'import');
    }
  }

  private async update() {
    this.importPayload.sourceId = sourceStore.currentSource.id;
    const formData = ImportPayload.toFormData(this.importPayload);
    const [data, errors] = await easync(this.updateImport(this.importPayload.id, formData, ImportsMixinTags.Update));

    if (data) {
      showToast('Import aggiornato correttamente!');
      this.$emit('created', data);
      this.hide();
    } else {
      showErrorToast('Si è verificato un errore nell\'aggiornamento dell\'import');
    }
  }
}
