













import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
import VSelect, { VSelectOption } from "@/components/VSelect.vue";
import { RequestData } from "@/store/types/RequestStore";
import { easync } from "@/utils/http";
import { showErrorToast } from "@/utils/Toast";
import { RegisterHttp } from "@/utils/Decorators";

import ImportsMixin, { ImportsMixinTags } from '@/mixins/http/ImportsMixin';
import { ImporterType } from "@/models";

@Component({ components: { VSelect } })
export default class ImporterTypeSelect extends Mixins(ImportsMixin) {
  @Prop()
  selected!: string | null;

  @Prop({ default: 'Selezione tipo di importer' })
  label?: string;

  @Prop()
  placeholder?: string;

  @Prop({ default: false })
  preselect: boolean;

  options: VSelectOption[] = [];
  private items: ImporterType[] = [];

  private value: string | null = this.selected || null;

  @RegisterHttp(ImportsMixinTags.ImporterTypes) request!: RequestData;

  mounted() {
    this.loadItems();
  }

  get filterDefault() {
    return { id: "all", text: "All" };
  }

  get loading() {
    return this.request?.loading;
  }

  onSelected(id: string) {
    this.value = id;
    this.$emit("input", id);

    const importerType = this.items.find(el => el.type === id);
    this.$emit('inputRaw', importerType);
  }

  onItemsReady() {
    if (!this.value) {
      if (this.preselect) {
        this.value = this.options[0].id;
      }
    } else {
      this.value = this.options.find(o => o.id === this.selected)?.id;
    }
  }

  async loadItems() {
    const [data, errors] = await easync(this.getImporterTypes(ImportsMixinTags.ImporterTypes));
    if (errors) {
      showErrorToast("Si è verificato un errore");
      return;
    }

    this.items = data;
    this.options = data.map((o: ImporterType) => {
      return { id: o.type, text: `${o.label}` };
    });
    this.onItemsReady();
  }
}
