







































import { Vue, Component, Ref, Mixins } from 'vue-property-decorator';

import VModal from '@/components/VModal.vue';
import { CLASS_NAME_TO_ROUTE } from '@/models/Import';

@Component({
  components: { VModal }
})
export default class ImportImportedResourcesModal extends Vue {
  @Ref() readonly modal!: VModal;

  private resources: any[] = [];

  show(resources: any[]) {
    this.resources = [...resources];
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  private resourceRouteParams(resource: any) {
    const routeName = CLASS_NAME_TO_ROUTE[resource.className];

    if (!routeName) {
      return null;
    }

    return {
      name: routeName.routeName,
      params: { id: resource.id }
    }
  }

  private resourceModelName(resource: any) {
    return CLASS_NAME_TO_ROUTE[resource.className].italianModelName;
  }
}
